import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"
import ExternalLink from "../../../components/externallink"

import { useTheme } from "../../../services/theme"


const HubspotEmbedCodeFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I add a Hubspot form to Vimkit using the form's embed code?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    maxWidth="960px"
                    title="How do I add a Hubspot form to Vimkit using the form's embed code?"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="480px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Choose a website </SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Websites' and choose the website you want to add the Hubspot form to.<br/><br/>
                        &#8594;Click 'Add Form'.<br/><br/>
                        &#8594;Click 'Add the form manually'.<br/><br/>
                    </SegmentText>
                    <SegmentSubTitle>Step 2: Provide the form's embed code </SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">

                        &#8594;Copy the form's embed code from Hubspot
                         (see 
                        <ExternalLink 
                            to="https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code"
                            display="inline"
                        >
                            &nbsp;Hubspot's documentation
                        </ExternalLink> for details).<br/><br/>
                         &#8594;Paste the embed code and click 'Add Form'.
                    </SegmentText>
                    <Screenshot fluid={data.addFormManualScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default HubspotEmbedCodeFaq

export const query = graphql`
    query {
        addFormManualScreenshot: file(relativePath: { eq: "screenshots/addformmanual.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`